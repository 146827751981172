import React, {Component, createRef} from 'react';
import BaseLayout from '../components/Layout/BaseLayout';
import {graphql} from 'gatsby';
import parse from 'html-react-parser';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Scrollspy from 'react-scrollspy';
import FaqAccordion from "../components/FaqAccordion";
import Scroll from "../components/Scroll";
import StickComponent from "../components/StickComponent";

let numOfSections = [];

class General extends Component {

    constructor(props){

        super(props);
        this.state = {
            show:false,
        }
    }

    componentDidMount(){
        this.setState({
            show:true,
        });

        // create an array with all the sections for ScrollSpy component
        for(let i = 0; i < this.props.data.gravql.page.modular.length; i++) {
            numOfSections = numOfSections.concat(`section-${i}`)
        }
    }

    renderChild(child) {

        switch(child.template) {

            case 'general':

                return  <div className="general-section__basic summary">
                    {
                        parse(child.content)
                    }
                </div>;

            case 'test':
                return <FaqAccordion data={child} className="general-section__faq faq"/>;

            default:
                return <div/>;
        }

    }

    render() {

        // create ref for scrolling on side-bar-menu click
        const refs = this.props.data.gravql.page.modular.reduce((acc, value, x) => {
            acc[x] = createRef();
            return acc;
        }, {});

        // scroll to the section on side-bar-menu click
        const handleClick = id => {
            refs[id].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }

        const {data, location} = this.props;


        return (
            <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title} showSubNav={true}>
            <section className="general-main">

                <Row>
                    <Col sm={12} lg={4} >
                        <StickComponent
                            canBeSticky={true}
                            topOffset={78}
                            scrollOffset={184}>
                            <div className="side-menu">
                            <Scrollspy items={numOfSections} currentClassName='is-current' offset={-150}>

                            {data.gravql.page.modular.map((child, x) => {
                                return (
                                    <li key={x}>
                                        <i className={child.header[0].body_classes}/>
                                        <Scroll type={'id'} element={`section-${x}`} offset={-150}>
                                            <a onClick={() => handleClick(x)}>{child.title}</a>
                                        </Scroll>
                                    </li>
                                )
                            })}

                            </Scrollspy>
                        </div>
                        </StickComponent>
                    </Col>
                    <Col sm={12} lg={8}>
                        {data.gravql.page && data.gravql.page.modular.map((child, x) => {
                            return (
                                <section ref={refs[x]} className={`general-section ${child.template}`} key={x} id={`section-${x}`}>
                                    {this.renderChild(child)}
                                </section>

                            )
                        })}
                    </Col>
                </Row>
            </section>
            </BaseLayout>
        )
    }
}

export default General;

export const query = graphql`
  query ($slug:String!) {
    gravql {
       pages {
            title
            route
            visible
              breadcrumbs
            header {
                title
                body_classes
            }
            children {
                title
                route
                header {
                    title
                    body_classes
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
             template 
            route
            breadcrumbs
            header {
                body_classes
            }
            modular {
                title
                route  
                id
                content  
                template 
                header {
                    body_classes
                    title
                }
                 modular {
                    title
                    route  
                    id
                    content  
                    template 
                    header {
                        body_classes
                        title
                    }
                    
                }
            }       
            metadata {
                name
                content
            }   
        }
    }
  }
`
