import React, {Component} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import parse from 'html-react-parser';


class FaqAccordion extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const {className, data} = this.props;

        return (
            <div className={className}>
                <h2>{data.title}</h2>
                <Accordion allowZeroExpanded={true} allowMultipleExpanded={false} >
                    {
                        data.modular && data.modular.map((child, x) => {
                            return (
                                <AccordionItem key={x}>
                                    <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {child.title}
                                    </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        {
                                        parse(child.content)
                                        }
                                    </AccordionItemPanel>
                                </AccordionItem>
                            )
                        })
                    }
                </Accordion>
            </div>)
    }
}

export default FaqAccordion;